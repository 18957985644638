
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from "@remix-run/react";
import { ExternalScripts } from "./components/ExternalScripts";
import { Provider } from "react-redux";
import { store } from "./store";
import { LinksFunction } from "@remix-run/node";
import { Toaster } from 'react-hot-toast';

export const links: LinksFunction = () => {
  return [
    {
      rel: "stylesheet",
      href: "/css/styles.min.css",
    }
  ];
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <Provider store={store}>
      <html lang="en">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <Meta />
          <Links />
          <ExternalScripts environment="prod" gtmTrackingId="GTM-WTDQZ9QB" />
        </head>
        <body>
          {children}
          <Toaster />
          <ScrollRestoration />
          <Scripts />
        </body>
      </html>
    </Provider>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  return (
    <div>
      <h1>Error</h1>
      <p>Something went wrong. Please try again later.</p>
    </div>
  );
}

export default function App() {
  return <Outlet />;
}